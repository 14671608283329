import React from "react";

export function usePreservedState<T>(initialState: T, key: string) {
  const [value, setValue] = React.useState<T>(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : initialState;
  });
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}

export function usePreservedDateRange<T>(initialState: T, key: string) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null
      ? {
          from: JSON.parse(stickyValue)?.fromTimestamp
            ? new Date(JSON.parse(stickyValue)?.fromTimestamp)
            : undefined,
          to: JSON.parse(stickyValue)?.toTimestamp
            ? new Date(JSON.parse(stickyValue)?.toTimestamp)
            : undefined,
          fromTimestamp: JSON.parse(stickyValue)?.fromTimestamp,
          toTimestamp: JSON.parse(stickyValue)?.toTimestamp,
        }
      : initialState;
  });
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}
