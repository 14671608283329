import { Filters } from ".";

export const UsersFilters: Filters[] = [
  {
    header: "All",
    key: "all",
    value: undefined,
  },
  {
    header: "Active",
    key: "is_active",
    value: 1,
  },
  {
    header: "Not Active",
    key: "is_active",
    value: 0,
  },
];
