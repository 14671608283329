import { FC } from "react";

export interface ErrorsType {
  errors: string[];
}

export const Errors: FC<ErrorsType> = ({ errors = [] }) => {
  return (
    <>
      {errors.map((error, index) => (
        <div
          key={index}
          className="rounded-md bg-red-50 p-2 mt-2 list-disc gap-y-1 text-sm text-red-700"
        >
          <div className="flex flex-row">{error}</div>
        </div>
      ))}
    </>
  );
};
