export type Filters = {
  header: string;
  key: string;
  value: any;
  should_be?: boolean;
};

export function createFilterObject(selectedTab: string, filterType: Filters[]) {
  if (selectedTab == "All") return {};
  let selectedFilter = filterType.find((i) => i.header == selectedTab);
  return {
    [selectedFilter?.key ? selectedFilter?.key : ""]: filterType.find(
      (i) => i.header == selectedTab
    )?.value,
  };
}

export * from "./admins-filters";
export * from "./merchants-filters";
export * from "./reconciliations-filters";
export * from "./terminals-filters";
export * from "./transactions-filters";
export * from "./users-filters";
